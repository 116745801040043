import { useState } from 'react';
import { Mail } from 'lucide-react';
import mainlogo from './mainlogo.png'

const ComingSoonPage = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setEmail('');
  };

  return (
    <div className="min-h-screen relative flex items-center justify-center">
      {/* Background Image with Overlay */}
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat backdrop-blur-xl"
        style={{
          backgroundImage: `url('/comingsoonbg.jpg')`,
          // backgroundImage: `url('https://st5.depositphotos.com/87146360/72571/i/450/depositphotos_725713848-stock-photo-cloud-sky-pastel-colored-background.jpg')`,
          // backgroundImage: `url('https://png.pngtree.com/thumb_back/fh260/background/20201009/pngtree-science-or-technology-background-with-dynamic-particles-trendy-colorful-design-template-image_406781.jpg')`,
          // backgroundImage: `url("https://t3.ftcdn.net/jpg/02/71/06/36/360_F_271063601_bF8ukMjLRIjK72cHpO79NJNY1bgmUACv.jpg")`
          backgroundImage: `url("https://cdn.vectorstock.com/i/500p/06/09/light-blue-textured-background-vector-53100609.jpg")`
        }}
      >
        <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" />
      </div>

      {/* Content */}
      <div className="relative z-10 max-w-xl mx-auto p-6 text-center">

        <img className='p-5' src={mainlogo} />

        <h1 className="text-3xl font-bold text-white mb-8 tracking-tight">
          Coming Soon!!!
        </h1>

        <p className="text-gray-200 text-xl mb-8">
          Something awesome is in the works. Stay tuned!<br />
          Our Team is working on it.
        </p>


      </div>
    </div>
  );
};

export default ComingSoonPage;